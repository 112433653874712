<template>
  <div class="userRead">
    <div class="top">
      <img src="@/assets/img/userRead_back.png" @click="backPage" />
    </div>
    <div class="center">
      <div class="video_box">
        <video ref="video" autoplay :muted="true" playsinline></video>
      </div>
      <img class="userRead_bg" src="@/assets/img/userRead_bg.png" alt="" />
    </div>
    <div class="button">
      <img
        class="photograph"
        src="@/assets/img/photograph.png"
        alt=""
        @click="takePhoto"
      />
    </div>
    <canvas v-show="false" ref="canvas" width="400" height="400"></canvas>
  </div>
</template>

<script>
import request from '@/utils/request.js'
import axios from 'axios'
import Compressor from 'compressorjs'

export default {
  data () {
    return {
      stream: null
    }
  },
  methods: {
    backPage () {
      this.$router.back()
    },
    async startCamera () {
      try {
        const video = this.$refs.video
        const constraints = {
          audio: false,
          video: { facingMode: 'user' } // 指定前置摄像头
        }
        this.stream = await navigator.mediaDevices.getUserMedia(constraints)
        video.srcObject = this.stream
      } catch (error) {
        console.error('Error accessing camera:', error)
      }
    },
    async takePhoto () {
      const video = this.$refs.video
      const canvas = this.$refs.canvas
      video.pause()
      // 将视频画面绘制到 canvas 中
      canvas.getContext('2d').drawImage(video, 0, 0, 400, 400)
      const photo = canvas.toDataURL('image/png')
      const file = this.base64ToFile(photo)
      this.compressImage(file, result => {
        this.uploadImg(result).then(res => {
          this.$router.back()
          this.$router.replace({
            path: '/identity',
            query: {
              userReadUrl: res,
              isFromCamera: 3
            }
          })
        })
      })
    },

    // 将 base64 编码的图片转换为 File 对象
    base64ToFile (base64String) {
      // 将base64的数据部分提取出来
      const arr = base64String.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      // 将Uint8Array转换为Blob对象
      const blob = new Blob([u8arr], { type: mime })

      // 创建File对象
      const file = new File([blob], 'image.png', { type: mime })

      return file
    },
    // 压缩图片
    compressImage (file, success) {
      let quality = 1
      const size = file.size
      const baseQuality = 1024 * 1024
      if (size < baseQuality) { // 小于1M
        quality = 0.8
      } else if (size < 5 * baseQuality) { // 小于5M
        quality = 0.4
      } else if (size < 10 * baseQuality) { // 小于10M
        quality = 0.1
      } else { // 大于10M
        quality = 0
      }
      new Compressor(file, {
        quality,
        success
      })
    },
    getUploadParams () {
      return request.post('updrloadImgToken')
    },
    // 上传图片到阿里云
    uploadImg (file) {
      return new Promise((resolve, reject) => {
        this.getUploadParams().then(res => {
          const key = 'web/' + Date.now() + this.randomString(10) + '-' + file.name
          const formData = new FormData()
          formData.append('OSSAccessKeyId', 'LTAI5tD9wrLfvn8sEpRLfpbw')
          formData.append('key', key)
          formData.append('policy', res.podrlicy)
          formData.append('signature', res.sidrgnature)
          formData.append('file', file)
          formData.append('success_action_status', 200)
          axios.post('https://inphotos.oss-ap-southeast-1.aliyuncs.com', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => resolve(`https://inphotos.oss-ap-southeast-1.aliyuncs.com/${key}`))
            .catch(error => {
              reject(error)
            })
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 生成随机图片名
    randomString (len = 32) {
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const maxPos = chars.length
      let pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    }
  },

  beforeDestroy () {
    if (this.stream) {
      // 关闭相机
      this.stream.getTracks().forEach(function (track) {
        track.stop()
      })
    }
  },

  mounted () {
    this.startCamera()
  }
}

</script>

<style scoped lang='less'>
.userRead {
  height: 100vh;
  background: #333333;
  display: flex;
  flex-direction: column;
  .top {
    flex: 1;
    img {
      width: 100px;
    }
  }
  .center {
    position: relative;
    height: 1000px;
    background: #fff;
    .video_box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 710px;
      width: 710px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      video {
        text-align: center;
        height: 750px;
      }
    }

    .userRead_bg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 671px;
    }
  }
  .button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .photograph {
      width: 144px;
    }
  }
}
</style>
